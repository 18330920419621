




























import { ApexLocales } from '@/views/chat/statistics/logic/defaultApexSettings'
import { ChatStatistics } from '@/includes/Api/Statistics.api';

import { UseFields } from "piramis-base-components/src/components/Pi";
import TableExportButtons from "piramis-base-components/src/components/TableExportButtons/TableExportButtons.vue";
import NewColumnChart from 'piramis-base-components/src/components/NewCharts/components/NewColumnChart.vue'
import { seriesByMetricInList } from 'piramis-base-components/src/shared/utils/seriesByMetricInList'
import { fromToTicks } from 'piramis-base-components/src/shared/utils/fromToTicks'
import { ApexChartSeries } from 'piramis-base-components/src/shared/types/ApexChartSeries.types'
import HelpMessage from 'piramis-base-components/src/components/HelpMessage/HelpMessage.vue';

import { Component, Prop, } from "vue-property-decorator";
import moment from "moment";
import { cloneDeep } from 'lodash'
import VueApexCharts from 'vue-apexcharts'
import { ApexOptions } from 'apexcharts'

@Component({
  components: {
    NewColumnChart,
    TableExportButtons,
    VueApexCharts,
    HelpMessage
  }
})
export default class NewUsers extends UseFields {
  @Prop() rawData!: ChatStatistics

  @Prop() period!: { from: string, to: string }

  dayTable: any = null

  chartTemplate = 0

  users: { series: ApexChartSeries, options: ApexOptions } | null = null

  mounted() {
    const fromTo = fromToTicks(moment(this.period.from).format('YYYY-MM-DD'),
                               moment(this.period.to).format('YYYY-MM-DD'),
                               { format: "YYYY-MM-DD" })

    seriesByMetricInList(
      this.$i18n,
      this.rawData.enter,
      [ 'enter_total', 'enter_first', 'enter_second', 'invited_total' ],
      fromTo,
      'date'
    ).calcSeries()
      .then(res => {
        this.users = {
          series: res,
          options: {
            chart: {
              animations: {
                enabled: false
              },
              defaultLocale: this.$i18n.locale,
              locales: ApexLocales,
            },
            xaxis: {
              labels: {
                datetimeUTC: false,
              },
              type: 'datetime',
              categories: fromTo.timeTicks
            },
            colors: [ 'rgba(var(--a-primary), 1)', 'rgba(var(--a-success), 1)', 'rgba(var(--a-warning), 1)', 'rgba(var(--a-danger), 1)' ],
            dataLabels: {
              enabled: false
            },
          }
        }

        this.getDayTable()
      })
  }

  getDayTable(): void {
    const fields = {
      [this.$t('statistics_total_new_users_title').toString()]: 0,
      [this.$t('statistics_unique_first_users_title').toString()]: 0,
      [this.$t('statistics_unique_repeated_users_title').toString()]: 0,
      [this.$t('statistics_unique_invited_users_title').toString()]: 0
    }

    const dates: Array<{[key: string]: string | number}> = fromToTicks(moment(this.period.from).format('YYYY-MM-DD'), moment(this.period.to).format('YYYY-MM-DD')).timeTicks.map(item => {
      return Object.assign({
        [this.$t('statistics_table_date_title').toString()]:item
      }, cloneDeep(fields))
    })

    this.rawData.enter.forEach(day => {
      let findItem = dates.find(item => item[this.$t('statistics_table_date_title').toString()] === day.date)
      if (findItem) {
        findItem[this.$t('statistics_total_new_users_title').toString()] = day.enter_total
        findItem[this.$t('statistics_unique_first_users_title').toString()] = day.enter_first
        findItem[this.$t('statistics_unique_repeated_users_title').toString()] = day.enter_second
        findItem[this.$t('statistics_unique_invited_users_title').toString()] = day.invited_total
      }
    })
    this.dayTable = dates
  }
}
